<template>
    <div class="bkColor">
        <div class="projectTitle">
            <div class="T">{{ collection.userName }}的项目收款详情</div>
            <div class="D">项目状态：{{ getReviewedStatus(status) }}</div>
            <img v-show="collection.projectState > 1 && 12 > collection.projectState" class="I"
                src="../../assets/icon/ytg_img.png" alt="">
            <img v-show="collection.projectState == 12" class="I" src="../../assets/icon/yjj_img.png" alt="">
        </div>
        <div class="projectBody">
            <van-field v-model="collection.projectName" label="项目信息" readonly autosize type="textarea" />
            <van-field v-model="collection.userName" label="填写人" readonly />
            <van-field v-model="collection.price" label="收款金额（元）" readonly />
            <van-field v-model="collection.description" label="收款说明" readonly autosize type="textarea" />
            <downloadFile :fileList="collection.fileList" :routerPath="`/auditDetails?id=${id}&type=2`">
            </downloadFile>
        </div>
    </div>
</template>

<script>
import { Field, Uploader, Toast } from 'vant'
import { reviewedPut } from '../../api/reviewed'
import Vue from 'vue';
Vue.use(Field)
Vue.use(Uploader)
Vue.use(Toast)
import { timeChange, projectStatusText } from '../../units/units'
import { getToken } from '../../units/auth'

import downloadFile from '../downloadFile.vue';
export default {
    name: 'collection',
    props: {
        obj: {
            typeof: Object,
            default: function () {
                return {

                }
            }
        },
        id: {
            typeof: Number,
            default: null
        },
        status: {
            typeof: Number,
            default: null
        }
    },
    components: {
        downloadFile
    },
    mounted() {
        this.id = this.$route.query.id
        this.type = this.$route.query.type
        this.queryFrom.userId = getToken('userId')
    },
    data() {
        return {
            title: '项目收款详情',
            type: null,
            queryFrom: {
                id: null,//项目id
                approvalComment: "",//审批意见
                flag: null,//是否同意 0否
                userId: 0,//审核人id
            },
            collection: {
                // projectName: '',//项目信息
                // userName: '',//收款人
                // price: '',//收款金额
                // description: '',//收款说明
                // fileList: []//附件
            },
            isReviewImg: false,//是否预览图片
            reViewImg: '',//预览的图片

        }
    },
    watch: {
        'obj': {
            handler(newVal) {
                this.collection = newVal
            }
        },
    },
    methods: {
        reViewFile(url) {
            if (url.indexOf('.jpg') !== -1 || url.indexOf('.jpeg') !== -1 || url.indexOf('.png') !== -1) {
                this.isReviewImg = true
                this.reViewImg = url
                return
            }
            this.$router.push({
                path: "/preview",
                query: { url: url, routerPath: `/auditDetails?id=${this.id}&type=${this.type}` },
            });
        },
        getTime(time) {
            return timeChange(time)
        },
        getReviewedStatus(s) {
            return projectStatusText(s)
        },
        getIdea(val) {
            this.queryFrom.id = this.id
            this.queryFrom.approvalComment = val.approvalComment
            this.queryFrom.flag = val.flag
            reviewedPut(this.queryFrom).then(res => {
                if (res.data.code == 200) {
                    Toast.success("提交成功");
                    this.$router.replace('/auditList');
                }
            })
        }

    }

}
</script>

<style lang="less" scoped>
.bkColor {
    width: 100vw;
    min-height: 100vh;
    background: #f6f6f6;
}

.projectTitle {
    width: 100vw;
    background: #fff;
    text-align: left;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 2vh;

    .T {
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 1.5vh
    }

    .D {
        color: #F0A239;
        font-size: 1em;
    }

    .I {
        width: 60px;
        height: 60px;
        position: absolute;
        top: 0;
        right: 70px;

    }
}

.projectBody {
    background: #fff;
    margin-bottom: 2vh;
}

/deep/ .van-cell {
    flex-wrap: wrap;
    margin-bottom: 1vh;
}

/deep/ .van-cell__title {
    color: #797979;
}

/deep/ .van-field__label,
.van-cell__title {
    width: 100vw;

}

/deep/ .van-cell::after {
    border-bottom: none;
}

.fileList {
    width: 100%;
    padding: 10px 16px;
    box-sizing: border-box;
    font-size: 14px;
    color: #797979;

    .title {
        text-align: left;
    }

    .fileImg {
        width: 80px;
        height: 80px;
    }

    .fileName {
        width: 110px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.reviewedList {
    padding: 10px 16px;
    background: #fff;

    .A {
        top: 30px !important;
    }

    .T {
        top: 35px !important
    }

    .U {
        top: 65px !important
    }

    .F {
        height: 110px !important;
    }
}

.title {
    font-size: 1rem;
    font-weight: 600;
    text-align: left;
}

.reviewed {
    position: relative;
    height: 160px;
    padding: 10px 20px;
    box-sizing: border-box;
    overflow: hidden;

    .avatar {
        width: 60px;
        height: 60px;
        position: absolute;
        top: 75px;
        left: 20px;
    }

    .status {
        position: absolute;
        top: 80px;
        left: 100px;
    }

    .time {
        position: absolute;
        top: 80px;
        right: 20px;
        color: #B4B4B4;
    }

    .userName {
        position: absolute;
        top: 110px;
        left: 100px;
        font-size: 1em;
        font-weight: 600;
    }
}
</style>