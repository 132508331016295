<template>
  <div class="bkColor">
    <headTitle :title="title" :routerPath="'/auditList'"></headTitle>
    <project v-if="type == 1" :id="id"></project>
    <collection v-else-if="type == 2" :id="id"></collection>
    <payment v-else-if="type == 3" :id="id"></payment>
    <reimbursement v-else-if="type == 4" :id="id"></reimbursement>
  </div>
</template>

<script>
import headTitle from "../components/headTitle.vue";

import collection from "../components/audit/collection.vue";
import payment from "../components/audit/payment.vue";
import project from "../components/audit/project.vue";
import reimbursement from "../components/audit/Reimbursement.vue";

// import { getReviewed } from "../api/reviewed";
export default {
  name: "auditDetails",
  components: {
    headTitle,
    collection,
    payment,
    project,
    reimbursement,
  },
  created() {
    this.id = this.$route.query.id;
    this.type = this.$route.query.type;
    // this.getAuditDetails();
  },
  data() {
    return {
      title: "项目审核详情",
      id: "",
      type: null,
      // auditDetails: {},
    };
  },
  methods: {
    // async getAuditDetails() {
    //   await getReviewed(this.id).then((res) => {
    //     this.auditDetails = res.data.data;
    //   });
    // },
  },
};
</script>

<style lang="less" scoped>
.bkColor {
  width: 100vw;
  min-height: 100vh;
  background: #f6f6f6;
}

.projectTitle {
  width: 100vw;
  background: #fff;
  text-align: left;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 2vh;

  .T {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 1.5vh;
  }

  .D {
    color: #f0a239;
    font-size: 0.5rem;
  }
}

.projectBody {
  background: #fff;
  margin-bottom: 2vh;
}

/deep/ .van-cell {
  flex-wrap: wrap;
  margin-bottom: 1vh;
}

/deep/ .van-cell__title {
  color: #797979;
}

/deep/ .van-field__label,
.van-cell__title {
  width: 100vw;
}

/deep/ .van-cell::after {
  border-bottom: none;
}

.fileList {
  width: 100%;
  padding: 10px 16px;
  box-sizing: border-box;
  font-size: 14px;
  color: #797979;

  .title {
    text-align: left;
  }

  .fileImg {
    width: 80px;
    height: 80px;
  }

  .fileName {
    width: 110px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.reviewedList {
  padding: 10px 16px;
  background: #fff;

  .A {
    top: 30px !important;
  }

  .T {
    top: 35px !important;
  }

  .U {
    top: 65px !important;
  }

  .F {
    height: 110px !important;
  }
}

.title {
  font-size: 1rem;
  font-weight: 600;
  text-align: left;
}

.reviewed {
  position: relative;
  height: 160px;
  padding: 10px 20px;
  box-sizing: border-box;
  overflow: hidden;

  .avatar {
    width: 60px;
    height: 60px;
    position: absolute;
    top: 75px;
    left: 20px;
  }

  .status {
    position: absolute;
    top: 80px;
    left: 100px;
  }

  .time {
    position: absolute;
    top: 80px;
    right: 20px;
    color: #b4b4b4;
  }

  .userName {
    position: absolute;
    top: 110px;
    left: 100px;
    font-size: 1em;
    font-weight: 600;
  }
}
</style>