<template>
    <div class="bkColor">
        <!-- <headTitle :title="pjDetail.userName + title"></headTitle> -->
        <div class="projectTitle">
            <div class="T">{{ pjDetail.userName }}的项目信息</div>
            <div class="D">{{ pjDetail.userName }} 提交于 {{ pjDetail.createTime }}</div>
            <img v-show="pjDetail.projectState > 1 && 12 > pjDetail.projectState" class="I"
                src="../../assets/icon/ytg_img.png" alt="">
            <img v-show="pjDetail.projectState == 12" class="I" src="../../assets/icon/yjj_img.png" alt="">
        </div>
        <div class="projectBody">
            <van-field v-model="pjDetail.projectName" readonly label="项目名称" autosize type="textarea" />
            <van-field v-model="pjDetail.projectNo" readonly label="项目编号" />
            <van-field v-model="pjDetail.personInCharge" readonly label="项目负责人" />
            <van-field v-model="pjDetail.projectTypeName" readonly label="项目类型" />
            <van-field v-model="pjDetail.projectIntroduction" readonly label="项目简介" autosize type="textarea" />
            <van-field v-model="address" readonly label="项目地址" />
            <van-field v-model="pjDetail.projectTypeName" readonly label="项目类型" />
            <van-field v-model="pjDetail.price" readonly label="项目金额（元）" />
            <van-field v-model="pjDetail.costBudget" readonly label="成本预算金额（元）" />
            <van-field v-model="pjDetail.startTime" readonly label="计划开始时间" />
            <van-field v-model="pjDetail.endTime" readonly label="计划结束时间" />
        </div>

        <process-details :list="pjDetail.projectReviewedList" :avatar="pjDetail.avatar" :status="status"
            :createTime="pjDetail.createTime" :userName="pjDetail.userName" @submitIdea="getIdea"></process-details>
    </div>
</template>

<script>
import Vue from 'vue';


import { pjStateText, timeChange, reviewedStatusText } from '../../units/units'

import { reviewedPut } from '../../api/reviewed'

import { Field, Tab, Tabs, Cell, List, PullRefresh, Icon, Button, Toast } from 'vant';
Vue.use(Field)
Vue.use(Tab);
Vue.use(Tabs)
Vue.use(Cell)
Vue.use(List)
Vue.use(PullRefresh)
Vue.use(Icon)
Vue.use(Button)
Vue.use(Toast)

import { getToken } from '../../units/auth';
import processDetails from '../processDetails.vue';
export default {
    name: 'project',
    props: {
        obj: {
            typeof: Object,
            default: function () {
                return {
                }
            }
        },
        id: {
            typeof: Number,
            default: null
        },
        status: {
            typeof: Number,
            default: null
        }
    },
    components: {
        // headTitle
        processDetails
    },
    data() {
        return {
            title: '的项目详情',
            queryFrom: {
                id: null,//项目id
                approvalComment: "",//审批意见
                flag: null,//是否同意 0否
                userId: 0,//审核人id
            },
            pjDetail: {},//详情对象
            address: ''
        }
    },
    watch: {
        'obj': {
            handler(newVal) {
                this.pjDetail = newVal
                this.address = newVal.city + " " + newVal.address
            }
        },
    },
    mounted() {
        this.pjDetail = this.obj
        this.queryFrom.userId = getToken('userId')
    },
    methods: {
        //获取项目状态
        stateText(t) {
            return pjStateText(t)
        },
        getTime(time) {
            return timeChange(time)
        },
        getReviewedStatus(s) {
            return reviewedStatusText(s)
        },
        getIdea(val) {
            this.queryFrom.id = this.id
            this.queryFrom.approvalComment = val.approvalComment
            this.queryFrom.flag = val.flag
            reviewedPut(this.queryFrom).then(res => {
                if (res.data.code == 200) {
                    Toast.success("提交成功");
                    this.$router.replace('/auditList');
                }
            })
        }

    }
}
</script>

<style lang="less" scoped>
.bkColor {
    width: 100vw;
    background: #f6f6f6;
    position: relative;
}

.projectTitle {
    width: 100vw;
    background: #fff;
    text-align: left;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 2vh;
    position: relative;

    .T {
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 1.5vh
    }

    .D {
        color: #797979;
        font-size: 0.5rem;
    }

    .I {
        width: 60px;
        height: 60px;
        position: absolute;
        top: 0;
        right: 70px;

    }
}

.projectBody {
    background: #fff;
    margin-bottom: 2vh
}

.projectTap .message /deep/ .van-cell__title {
    text-align: left;
}

/deep/ .van-tabs__content {
    background: #fff
}

.listCell {
    width: 100%;
    height: 14vh;
    box-sizing: border-box;
    padding-left: 5vw;
    padding-right: 5w;
    background: #fff;
    text-align: left;
    position: relative;
}

.cellName {
    width: 90%;
    font-size: 1rem;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-top: 2px solid #F6F6F6;
    // padding-top: 1vh;
    // padding-bottom: 1vh;
}

/deep/ .progress :first-of-type.listCell .cellName {
    border-top: unset;
}


.fileList {
    width: 100%;
    padding: 10px 16px;
    box-sizing: border-box;
    font-size: 14px;
    color: #797979;

    .title {
        text-align: left;
    }

    .fileImg {
        width: 80px;
        height: 80px;
    }

    .fileName {
        width: 110px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.reviewedList {
    padding: 10px 16px;
    background: #fff;
    margin-bottom: 2vh;

    .A {
        top: 30px !important;
    }

    .T {
        top: 35px !important
    }

    .U {
        top: 65px !important
    }

    .F {
        height: 110px !important;
    }
}

.title {
    font-size: 1rem;
    font-weight: 600;
    text-align: left;
}

.reviewed {
    position: relative;
    height: 160px;
    padding: 10px 20px;
    box-sizing: border-box;
    overflow: hidden;

    .avatar {
        width: 60px;
        height: 60px;
        position: absolute;
        top: 75px;
        left: 20px;
    }

    .status {
        position: absolute;
        top: 80px;
        left: 100px;
    }

    .time {
        position: absolute;
        top: 80px;
        right: 20px;
        color: #B4B4B4;
    }

    .userName {
        position: absolute;
        top: 110px;
        left: 100px;
        font-size: 1em;
        font-weight: 600;
    }
}
</style>