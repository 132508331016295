import request from "./request";

//新增项目审核
export function reviewedAdd(data) {
    return request({
        url: '/project/reviewed',
        method: 'post',
        data
    })
}

//修改项目审核
export function reviewedPut(params) {
    return request({
        url: '/project/reviewed/approval/',
        method: 'put',
        params
    })
}

//获取项目审核
export function getReviewed(id) {
    return request({
        url: '/project/reviewed/' + id,
        method: 'get'
    })
}

//删除项目审核
export function reviewedDeltel(ids) {
    return request({
        url: '/project/reviewed/' + ids,
        method: 'delete'
    })
}

//导出项目审核
export function reviewedExport() {
    return request({
        url: '/project/reviewed/export',
        method: 'post'
    })
}

//导入项目审核
export function reviewedImportData(data) {
    return request({
        url: '/project/reviewed/importData',
        method: 'post',
        data
    })
}

//获取项目审核列表
export function reviewedList(query) {
    return request({
        url: '/project/reviewed/list',
        params: query
    })
}
