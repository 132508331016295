<template>
  <div>
    <div class="bkColor">
      <div class="projectTitle">
        <div class="T">{{ paymentDetail.obj.projectName ? paymentDetail.obj.projectName + "的付款详情" :
          paymentDetail.obj.userName + "的非项目付款详情 " }}</div>
        <div class="D">
          项目状态：{{ getReviewedStatus(paymentDetail.obj.state) }}
        </div>
        <div style="font-size: 14px">流水号: {{ paymentDetail.obj.paymentNo }}</div>
        <img v-show="paymentDetail.obj.state == 1" class="I" src="../../assets/icon/ytg_img.png" alt="" />
        <img v-show="paymentDetail.obj.state == 2" class="I" src="../../assets/icon/yjj_img.png" alt="" />
      </div>
      <div class="projectBody">
        <van-field v-if="paymentDetail.obj.projectName" v-model="paymentDetail.obj.projectName" label="项目信息" readonly
          autosize type="textarea" rows="1" />
        <van-field v-model="projectBelong" label="归属单位" readonly />
        <van-field v-model="paymentDetail.obj.userName" label="填写人" readonly />
        <van-field v-model="paymentDetail.obj.supplier" label="供应商" readonly />
        <van-field v-model="paymentDetail.obj.paymentName" label="费用类型" readonly />
        <van-field v-model="paymentDetail.obj.price" label="付款金额（元）" readonly />
        <van-field v-model="paymentDetail.obj.description" label="付款说明" readonly autosize type="textarea" />
        <downloadFile :fileList="paymentDetail.obj.fileList" :routerPath="routerPath">
        </downloadFile>
      </div>
      <!-- 
      <process-details :list="paymentDetail.obj.reviewedList" :avatar="paymentDetail.obj.avatar"
        :status="paymentDetail.status" :createTime="paymentDetail.obj.createTime" :userName="paymentDetail.obj.userName"
        :copyList="paymentDetail.obj.copyList" @submitIdea="getIdea" :belongUserId="paymentDetail.userId"
        :routerPath="routerPath"></process-details> -->
      <process-details :list="paymentDetail.obj.reviewedList" :avatar="paymentDetail.obj.avatar"
        :status="paymentDetail.status" :createTime="paymentDetail.obj.createTime" :userName="paymentDetail.obj.userName"
        :copyList="paymentDetail.obj.copyList" :routerPath="routerPath"></process-details>


      <!-- <van-goods-action>
        <van-goods-action-icon v-if="equipment" icon="description" text="导出PDF" @click="pdf = true" />
        <van-goods-action-icon icon="chat-o" text="评论" @click="isComment = true" />
      </van-goods-action> -->

      <van-field v-show="paymentDetail.status == 3 && queryFrom.userId == paymentDetail.userId"
        v-model="queryFrom.approvalComment" rows="1" autosize label="审核意见" type="textarea" placeholder="请输入审核意见" />

      <van-popup v-model="isComment" round closeable @click-close-icon="isComment = false">
        <sandBox :formId="paymentDetail.belongId" :type="3" :routerPath="routerPath" @succesSend="pageUpdate"
          :promoterId="paymentDetail.obj.userId"></sandBox>
      </van-popup>
    </div>

    <div class="footContent">
      <!-- <div class="footButton" @click="pdf = true">
        <van-icon name="description" size="1.5em" />
        导出PDF
      </div> -->
      <div class="footButton" @click="isComment = true">
        <van-icon name="chat-o" size="16px" />
        评论
      </div>
      <div v-if="paymentDetail.status == 3 && queryFrom.userId == paymentDetail.userId" style="margin-left:auto">
        <van-button @click="getIdea(0)" style="margin-right:20px">审核拒绝</van-button>
        <van-button type="info" @click="getIdea(1)">审核同意</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, Uploader, Toast, Popup, GoodsAction, GoodsActionIcon, Dialog, Icon, Button } from "vant";
import Vue from "vue";
Vue.use(Field);
Vue.use(Uploader);
Vue.use(Toast);
Vue.use(Popup)
Vue.use(GoodsAction);
Vue.use(GoodsActionIcon);
Vue.use(Dialog)
Vue.use(Icon)
Vue.use(Button)

import { timeChange, projectStatusText } from "../../units/units";


import { getReviewed } from "../../api/reviewed";

import { getToken } from "../../units/auth";
import { reviewedPut } from "../../api/reviewed";
import processDetails from "../processDetails.vue";
import downloadFile from "../downloadFile.vue";
import sandBox from "../inputBox/sandBox.vue";
import { ddShare } from "../../units/dd";
export default {
  name: "payment",
  props: {
    // obj: {
    //   typeof: Object,
    //   default: function () {
    //     return {};
    //   },
    // },
    id: {
      typeof: Number,
      default: null,
    },
    // status: {
    //   typeof: Number,
    //   default: null,
    // },
    // belongId: {
    //   typeof: String,
    //   default: null,
    // },
  },
  components: {
    processDetails,
    downloadFile,
    sandBox
  },
  data() {
    return {
      title: "项目付款详情",
      type: null,
      queryFrom: {
        id: null, //项目id
        approvalComment: "", //审批意见
        flag: null, //是否同意 0否
        userId: 0, //审核人id
      },
      paymentDetail: {
        obj: {
          belong: null,
          projectName: '',//项目名称
          userName: '',//付款人
          supplier: '',//供应商
          price: '',//价格
          paymentTime: '',//付款时间
          description: '',//付款说明
          paymentName: '',//付款类型
          fileList: [],//文件列表
          reviewedList: [],//审核人列表
          copyList: []//抄送人列表
        }
      },
      userId: '',
      isReviewImg: false, //是否预览图片
      reViewImg: "", //预览的图片,

      isComment: false,//是否显隐评论框
      pdf: false,//是否显隐pdf预览
      htmlTitle: '',//导出pdf的文件名
      equipment: false,//判断是否为移动端
      routerPath: '',
      projectBelong: ''
    };
  },
  // watch: {
  //   obj: {
  //     handler(newVal) {
  //       this.paymentDetail = newVal;
  //     },
  //   },
  // },
  mounted() {
    this.getAuditDetails()
    this.type = this.$route.query.type;
    this.routerPath = `/auditDetails?id=${this.id}&type=${this.type}`
    this.queryFrom.userId = getToken("userId");
  },
  methods: {
    getAuditDetails() {
      getReviewed(this.id).then((res) => {
        if (res.data.code == 200) {
          this.paymentDetail = res.data.data;
          this.projectBelong = this.paymentDetail.obj.belong == 1 ? '大美' : '中创'
          this.pageShare()
        }

      });
    },
    reViewFile(url) {
      if (
        url.indexOf(".jpg") !== -1 ||
        url.indexOf(".jpeg") !== -1 ||
        url.indexOf(".png") !== -1
      ) {
        this.isReviewImg = true;
        this.reViewImg = url;
        return;
      }
      this.$router.push({
        path: "/preview",
        query: {
          url: url,
          routerPath: this.routerPath,
        },
      });
    },
    getTime(time) {
      return timeChange(time);
    },
    getReviewedStatus(s) {
      return projectStatusText(s);
    },
    getIdea(val) {

      Dialog.confirm({
        message: `是否${val == 0 ? "拒绝" : "同意"}`,
        confirmButtonColor: "#0092fe",
      }).then(() => {
        this.queryFrom.id = this.id;
        this.queryFrom.flag = val;
        reviewedPut(this.queryFrom).then((res) => {
          if (res.data.code == 200) {
            Toast.success("提交成功");
            this.$router.replace("/auditList");
          }
        });
      });
    },


    pageUpdate() {
      this.getAuditDetails()
      this.isComment = false
    },
    // paymentRevoke() {
    //   if (this.paymentDetails.state > 0 || this.userId !== this.paymentDetails.userId) {
    //     return
    //   }
    //   Dialog.confirm({
    //     message: `确定撤销？`,
    //     confirmButtonColor: '#0092fe'
    //   }).then(() => {
    //     paymentDeltel([this.id]).then(res => {
    //       if (res.data.code == 200) {
    //         Toast.success('撤销成功')
    //         this.$router.replace('/pjPayment');
    //       }
    //     })
    //   })
    // }
    pageShare() {
      let name = this.paymentDetail.obj.projectName ? this.paymentDetail.obj.projectName + '的付款详情' : this.paymentDetail.obj.userName + '的' + this.paymentDetail.obj.paymentName + '付款详情'
      ddShare(this.routerPath, name)
    }

  },
};
</script>

<style lang="less" scoped>
/deep/ .van-cell__title {
  font-weight: 600;
  font-size: 16px;
  color: #2c3e50 !important;
}

/deep/ .van-button {
  border-radius: 6px;
}

.bkColor {
  width: 100vw;
  background: #f6f6f6;
  padding-bottom: 9vh;
  overflow: auto;
}

.projectTitle {
  width: 100vw;
  background: #fff;
  text-align: left;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 2vh;
  position: relative;

  .T {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 1.5vh;
  }

  .D {
    color: #f0a239;
    font-size: 1em;
  }

  .I {
    width: 60px;
    height: 60px;
    position: absolute;
    top: 4vh;
    right: 70px;
  }
}

.projectBody {
  background: #fff;
}

/deep/ .van-cell {
  flex-wrap: wrap;
  margin-bottom: 1vh;
}

/deep/ .van-cell__title {
  color: #797979;
}

/deep/ .van-field__label,
.van-cell__title {
  width: 100vw;
}

/deep/ .van-cell::after {
  border-bottom: none;
}

.fileList {
  width: 100%;
  padding: 10px 16px;
  box-sizing: border-box;
  font-size: 14px;
  color: #797979;

  .title {
    text-align: left;
  }

  .fileImg {
    width: 80px;
    height: 80px;
  }

  .fileName {
    width: 110px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.reviewedList {
  padding: 10px 16px;
  background: #fff;

  .A {
    top: 30px !important;
  }

  .T {
    top: 35px !important;
  }

  .U {
    top: 65px !important;
  }

  .F {
    height: 110px !important;
  }
}

.title {
  font-size: 1rem;
  font-weight: 600;
  text-align: left;
}

.reviewed {
  position: relative;
  height: 160px;
  padding: 10px 20px;
  box-sizing: border-box;
  overflow: hidden;

  .avatar {
    width: 60px;
    height: 60px;
    position: absolute;
    top: 75px;
    left: 20px;
  }

  .status {
    position: absolute;
    top: 80px;
    left: 100px;
  }

  .time {
    position: absolute;
    top: 80px;
    right: 20px;
    color: #b4b4b4;
  }

  .userName {
    position: absolute;
    top: 110px;
    left: 100px;
    font-size: 1em;
    font-weight: 600;
  }
}

.footContent {
  width: 100vw;
  height: 9vh;
  padding: 0 10px;
  box-sizing: border-box;
  position: fixed;
  // top: 0;
  // right: 0;
  bottom: 0;
  left: 0;
  background: #f6f6f6;
  display: flex;
  align-items: center;
}

.footButton {
  width: 60px;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
</style>